import { useFederatedComponent } from '@/micro-frontends/hooks/useFederatedComponent';
import React from 'react';
import { componentNameToMyPhxKebabFormat } from '@/helpers/string-utils';
import { Box } from '@mui/material';

interface RemoteComponentProps {
  remoteUrl: string;
  scope: string;
  module: string;
  props?: object;
  isEnabled: boolean;
  loadingComponent?: React.ReactElement | null;
  fallbackComponent?: React.ReactElement | null;
}

function RemoteComponent(
  {
    remoteUrl,
    scope,
    module,
    props,
    isEnabled, // pass this here so that we can prevent the script from loading when disabled
    loadingComponent = null,
    fallbackComponent = null,
  } : RemoteComponentProps,
) {
  const { Component: FederatedComponent, isError, isLoading } = useFederatedComponent(
    {
      remoteUrl,
      scope,
      module,
      isEnabled,
    },
  );

  if (isLoading) {
    return (
      <Box data-testid="remote-component-loading">
        {loadingComponent}
      </Box>
    );
  }

  if (isError || (!FederatedComponent && !isLoading) || !isEnabled) {
    return fallbackComponent;
  }

  return (
    <Box data-component={componentNameToMyPhxKebabFormat(`-mfe-${scope}`)}>
      <FederatedComponent {...props} />
    </Box>
  );
}

export default RemoteComponent;
