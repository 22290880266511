import { Fade } from '@mui/material';
import RemoteComponent from '@/micro-frontends/remote-component';
import SkillCard from '@/components/molecules/skill-card';
import config from '@/helpers/config';
import useGetStepCardState from '@/micro-frontends/careers/step-card/useGetStepCard.state';
import { Loading, SpinnerWrapper, StyledCard } from './StepCard.styles';

function MFECareersStepCard() {
  const { careers } = config;
  const { baseUrl, mfeBasePath } = careers;
  const { isEnabled, hasSavedCareer, hasSavedJobTitle } = useGetStepCardState();

  return (
    <RemoteComponent
      remoteUrl={`${baseUrl}${mfeBasePath}remoteEntry.js`}
      scope="careerPersonalizationStepCard"
      module="./StepCard"
      isEnabled={isEnabled}
      props={{ hasSavedCareer, hasSavedJobTitle }}
      loadingComponent={(
        <StyledCard>
          <SpinnerWrapper>
            <Fade in timeout={500} style={{ transitionDelay: '500ms' }}>
              <Loading />
            </Fade>
          </SpinnerWrapper>
        </StyledCard>
    )}
      fallbackComponent={<SkillCard />}
    />
  );
}

export default MFECareersStepCard;
