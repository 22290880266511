import { useEffect, useState } from 'react';
/* eslint max-len: 0 */
/*
This is a reference from the module federation dynamic remotes example
https://github.com/module-federation/module-federation-examples/blob/master/advanced-api/dynamic-remotes-runtime-environment-variables/host/src/hooks/useDynamicScript.js
This hook is used to load a dynamically imported script.
It takes in a url and creates a <script> with the url as the src and adds it in the dom
 */
const urlCache = new Set();
const useDynamicScript = (url: string) => {
  const [isReady, setIsReady] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (!url) return;

    if (urlCache.has(url)) {
      setIsReady(true);
      setIsError(false);
      return;
    }

    setIsReady(false);
    setIsError(false);

    const element = document.createElement('script');

    element.src = url;
    element.type = 'text/javascript';
    element.async = true;

    element.onload = () => {
      urlCache.add(url);
      setIsReady(true);
    };

    element.onerror = () => {
      setIsReady(false);
      setIsError(true);
    };

    document.head.appendChild(element);

    // eslint-disable-next-line consistent-return
    return () => {
      urlCache.delete(url);
      document.head.removeChild(element);
    };
  }, [url]);

  return {
    isError,
    isReady,
  };
};

export default useDynamicScript;
