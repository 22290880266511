import features from '@/helpers/features';
import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import useGetProgramsByIdHook from '@/store/hooks/useGetProgramsByIdHook';
import { useGetLatestWorkflowQuery } from '@/store/queries/workflows';
import { StudentCourseMembership } from '@/types/api/student/studentCourseMembership';
import { useGetMembershipsByPersonIdAndDateRangeQuery } from '@/store/queries/v2/student';
import { getCurrentCourseMemberships, getFutureCourseMemberships } from '@/myphoenix/utils/course-functions';
import { isEnrollmentApplicationActive } from '@/myphoenix/utils/enrollment-functions';
import AlumniNAEGuidanceUi from './AlumniNAEGuidance.ui';

function AlumniNAEGuidance({ children }: any) {
  const personId = getPersonIdFromSessionStorage();

  // alumni re-enroll feature flag enabled
  const alumniReEnrollEnabled = features.alumniReenrollEnabled;

  // has a primary program
  const {
    data: { primaryProgram } = {},
    isFetching: programsFetching,
  } = useGetProgramsByIdHook(personId);

  // is an alumni
  const enrollmentStatus = primaryProgram?.statuses
    ? primaryProgram?.statuses?.find((status: any) => status?.statusType === 'EnrollmentStatus')
    : undefined;

  const {
    data: { applicationStartDate, applicationType } = {},
  } = useGetLatestWorkflowQuery(
    { personId },
    { skip: !personId },
  );

  const alumni = enrollmentStatus !== null && enrollmentStatus?.status === 'G';
  const alumniApplicationStudent = alumni && applicationStartDate !== null
    && isEnrollmentApplicationActive(applicationStartDate) && applicationType === 'ALUMNI';

  // has no future or current courses
  const {
    data: memberships = [],
  } = useGetMembershipsByPersonIdAndDateRangeQuery(
    { personId },
    { skip: !personId },
  ) as {
    data: StudentCourseMembership[],
  };
  const currentCourseMemberships = getCurrentCourseMemberships(memberships);
  const futureCourseMemberships = getFutureCourseMemberships(memberships, 0);
  const hasNoFutureOrCurrentCourses = !currentCourseMemberships.length
    && !futureCourseMemberships.length;

  // Show Tooltip
  const showAlumniNAEGuidance = alumniReEnrollEnabled
    && hasNoFutureOrCurrentCourses
    && alumniApplicationStudent
    && !sessionStorage.getItem('dismissAlumniNAEGuidanceAlert')
    && !programsFetching;

  const onDismiss = () => {
    sessionStorage.setItem('dismissAlumniNAEGuidanceAlert', 'true');
  };

  return (
    <AlumniNAEGuidanceUi
      program={primaryProgram?.description}
      isOpen={showAlumniNAEGuidance}
      onDismiss={onDismiss}
    >
      {children}
    </AlumniNAEGuidanceUi>
  );
}

export default AlumniNAEGuidance;
