import { FeatureVariableKeys } from '@/helpers/features';
import { useVariableValue } from '@devcycle/react-client-sdk';

const UseGetInterrupterState = () => {
  const isEnabled = useVariableValue(FeatureVariableKeys.HomePageInterrupter, false);

  const shouldShowInterrupter = isEnabled;

  return {
    shouldShowInterrupter,
  };
};

export default UseGetInterrupterState;
