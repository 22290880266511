import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import useDynamicScript from './useDynamicScript';
/* eslint max-len: 0 */
/*
 This is a reference from the module federation dynamic remotes example
 https://github.com/module-federation/module-federation-examples/blob/master/advanced-api/dynamic-remotes-runtime-environment-variables/host/src/hooks/useFederatedComponent.js
 This hook is used to load a dynamically imported component.
 It takes in a url, scope and module and returns a component, along with returning whether there was an error loading the component
 */

interface FederatedComponentProps {
  remoteUrl: string;
  scope: string;
  module: string;
  isEnabled: boolean;
}

function loadComponent(scope: string, module: string) {
  return async () => {
    // Initializes the share scope. This fills it with known provided modules from this build and all remotes
    await __webpack_init_sharing__('default');
    // @ts-ignore we really wont know what each scope name will be
    const container = window[scope]; // or get the container somewhere else
    // Initialize the container, it may provide shared modules
    // @ts-ignore __webpack_share_scopes__ is not something we can import as a type
    await container.init(__webpack_share_scopes__.default);
    const factory = await container.get(module);
    return factory();
  };
}

export const useFederatedComponent = ({ remoteUrl, scope, module, isEnabled } : FederatedComponentProps) => {
  const [Component, setComponent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const isWindowDefined = typeof window !== 'undefined';

  const { isReady, isError } = useDynamicScript(remoteUrl);

  useEffect(() => {
    if (isReady && !Component && isEnabled && isWindowDefined) {
      const Comp = dynamic(loadComponent(scope, module));
      setComponent(Comp);
      setIsLoading(false);
    }

    if ((isReady && !isEnabled) || (isError)) {
      setComponent(null);
      setIsLoading(false);
    }
  }, [Component, isReady, isError, isEnabled, isLoading, isWindowDefined, scope, module]);

  return { isError, Component, isLoading };
};

export default useFederatedComponent;
