import React from 'react';
import { Fade, useMediaQuery } from '@mui/material';
import useGetProgramsByIdHook from '@/store/hooks/useGetProgramsByIdHook';
import { useGetProgramInfoByProgramOfferingIdQuery } from '@/store/queries/programs';
import { useGetTasksQuery } from '@/store/queries/notifications';
import { useGetLatestWorkflowQuery } from '@/store/queries/workflows';
import { componentNameToMyPhxKebabFormat, makeBold } from '@/helpers/string-utils';
import theme from '@/helpers/theme';
import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import { useGetApplicationInfoByIdQuery } from '@/store/queries/application';
import { isEnrollmentApplicationActive } from '@/myphoenix/utils/enrollment-functions';
import features from '@/helpers/features';
import {
  StyledIconLongArrow,
  StyledCard,
  StyledIconEdit,
  StyledCircularProgress,
  StyledLink,
  TypographyErrorText,
  StyledDivContent,
  StyledTypographyMessage,
  StyledErrorButton,
  IconRefresh,
} from './ApplicationStatus.styles';

 type ApplicationStatusTypes = {
   errorLinkText?: string,
   errorMessageText?: string,
 };

export default function ApplicationStatus({
  errorLinkText = 'Reoload',
  errorMessageText = 'We’re sorry! We experienced an error retrieving this data. Please refresh your browser.',
}:ApplicationStatusTypes): any {
  const personId = getPersonIdFromSessionStorage();
  const componentName = 'ApplicationStatus';
  const mobile = useMediaQuery(
    theme.breakpoints.down('sm'),
  );
  const {
    data: { primaryProgram } = {},
    isFetching: statusPrograms,
    isError: errorProgram,
  } = useGetProgramsByIdHook(personId);

  const {
    data: { applicationType, applicationStartDate, application, applicationId } = {},
    isLoading: statusEnrollmentApplication,
    error: errorApplication,
  } = useGetLatestWorkflowQuery({ personId });

  const { data: { enrollmentTasks } = {},
    isLoading: statusTasks,
    error: tasksError } = useGetTasksQuery(
    { personId, applicationId },
  );

  const {
    data: { programOfferingId } = {},
    isLoading: statusEnrollmentApplicationInfo,
  } = useGetApplicationInfoByIdQuery(
    { applicationId },
    { skip: !applicationId },
  );

  const {
    data: programOffering,
    isLoading: statusProgramOfferingLoading,
  } = useGetProgramInfoByProgramOfferingIdQuery(
    { programOfferingId },
    { skip: !programOfferingId },
  );

  const hasIncompleteEnrollmentTasks = enrollmentTasks?.length > 0;
  const isLoading = statusPrograms || statusEnrollmentApplication
      || statusTasks || statusProgramOfferingLoading || statusEnrollmentApplicationInfo;
  const enrollmentApplicationProgram = programOffering?.description;
  const returnedError = errorProgram || errorApplication || tasksError;
  let statusMessage = '';
  const mobileStatusMessage = 'New Program Enrollment';
  let statusLinkText = '';
  let mobileStatusLinkText = '';
  let statusLink = '';
  let statusIcon = '';
  const enrollmentApplicationStatus = application?.submissionStatus;
  const enrollmentStatus = primaryProgram?.statuses ? primaryProgram.statuses.find((status: { statusType: string }) => (status.statusType === 'EnrollmentStatus')) : null;
  const alumniNearGrad = (enrollmentStatus
  && (applicationType === 'ALUMNI' && enrollmentStatus?.status === 'C'));
  const nearGradStudent = (applicationType === 'NEAR GRAD' || alumniNearGrad)
  && (enrollmentApplicationStatus !== 'COMPLETED' || hasIncompleteEnrollmentTasks)
  && isEnrollmentApplicationActive(applicationStartDate);

  if (application && !isLoading) {
    if (!hasIncompleteEnrollmentTasks) {
      statusIcon = 'icon-document-edit-mc';
      statusMessage = makeBold(
        `Your application for ${enrollmentApplicationProgram} is incomplete`,
        enrollmentApplicationProgram,
      );
      if (!enrollmentApplicationProgram) {
        statusMessage = 'Your application for your new program is incomplete.';
      }
      statusLinkText = 'Return to application';
    } else if (hasIncompleteEnrollmentTasks) {
      statusIcon = 'icon-bell-badge-mc';
      statusMessage = makeBold(
        `You have enrollment tasks for ${enrollmentApplicationProgram}`,
        enrollmentApplicationProgram,
      );
      if (!enrollmentApplicationProgram) {
        statusMessage = 'You have enrollment tasks for your new program.';
      }
      statusLinkText = 'View tasks';
      mobileStatusLinkText = 'View enrollment tasks';
      statusLink = '/message-center.html';
    }
  }

  if (!(features?.nearGradReenrollEnabled && nearGradStudent)) {
    return null;
  }
  if (!returnedError && (isLoading === true)) {
    return (
      <StyledCard
        data-testid="application_status_loading"
        $loading
        data-component={componentNameToMyPhxKebabFormat(componentName)}
      >
        <Fade in timeout={500} style={{ transitionDelay: '500ms' }}>
          <StyledCircularProgress aria-label="Progress bar" />
        </Fade>
      </StyledCard>
    );
  }

  if (returnedError && !mobile) {
    statusMessage = errorMessageText;
  }

  return (
    <StyledCard data-testid="application_status" $loading={false} data-component={componentNameToMyPhxKebabFormat(componentName)}>
      <StyledDivContent>
        { !returnedError && statusIcon === 'icon-document-edit-mc' ? (
          <StyledIconEdit icon="icon-document-edit-mc" id={statusIcon} aria-hidden={false} />
        ) : (<StyledIconEdit icon="icon-bell-badge-mc" id={statusIcon} aria-hidden={false} />) }
        <StyledTypographyMessage
          variant="body4"
          aria-label="Application status message"
          dangerouslySetInnerHTML={{
            __html: mobile && mobileStatusMessage ? mobileStatusMessage : statusMessage,
          }}

        />
      </StyledDivContent>
      {(returnedError && mobile) && (
        <TypographyErrorText aria-label="Error Message">
          {returnedError.message}
        </TypographyErrorText>
      )}
      {returnedError ? (
        <div>
          <StyledErrorButton
            type="button"
            onClick={() => { window.location.reload(); }}
            aria-label="Refresh Error Button"
          >
            <IconRefresh icon="icon-refresh" id="icon-refresh" aria-hidden={false} />
            {errorLinkText}
          </StyledErrorButton>
        </div>
      ) : (
        <StyledLink
          underline="none"
          href={statusLink}
          aria-label="View Enrollment/Application Link "
        >
          {mobile && mobileStatusLinkText ? mobileStatusLinkText : statusLinkText}
          <StyledIconLongArrow icon="icon-long-arrow" id="icon-arrow" />
        </StyledLink>
      )}
    </StyledCard>
  );
}
